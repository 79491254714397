.title {
    font-size: 34px; /* Assuming rem() is not needed */
    font-weight: 900;
}

/* Media Query */
@media (max-width: 768px) { /* Adjust breakpoint as needed */
    .title {
        font-size: 24px;
    }
}

.description {
    max-width: 600px; /* Assuming rem() is not needed */
    margin: auto;
}

.description::after {
    content: '';
    display: block;
    background-color: var(--mantine-color-blue-filled);
    width: 45px; /* Assuming rem() is not needed */
    height: 2px; /* Assuming rem() is not needed */
    margin-top: var(--mantine-spacing-sm);
    margin-left: auto;
    margin-right: auto;
}

.card {
    border: 1px solid var(--mantine-color-gray-1); /* Assuming light-dark() is replaced with a specific color variable */
}

.cardTitle::after {
    content: '';
    display: block;
    background-color: var(--mantine-color-blue-filled);
    width: 45px; /* Assuming rem() is not needed */
    height: 2px; /* Assuming rem() is not needed */
    margin-top: var(--mantine-spacing-sm);
}
