.hero {
    background-color: #2a2a2a; /* Dark grey color */
    background-image: linear-gradient(250deg, rgba(42, 42, 42, 0) 0%, rgba(10, 10, 10, 0.8) 70%),
    url(https://images.unsplash.com/photo-1453828423292-392a660a502f?q=80&w=3264&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    zIndex: -1;
}


.inner {
    display: flex;
    justify-content: space-between;
}

.image {
    display: block; /* Default display for non-mobile */
}

.content {
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    margin-right: calc(var(--mantine-spacing-xl) * 20);
}

.title {
    color: var(--mantine-color-white);
    font-family: Greycliff CF, var(--mantine-font-family);
    font-weight: 900;
    line-height: 1.05;
    max-width: 500px; /* Use px instead of rem */
    font-size: 48px;  /* Use px instead of rem */
}

.description {
    color: var(--mantine-color-white);
    opacity: 1.0;
    max-width: 500px; /* Use px instead of rem */
    font-size: 20px;
}

.control {
    padding-left: 50px; /* Use px instead of rem */
    padding-right: 50px; /* Use px instead of rem */
    font-family: Greycliff CF, var(--mantine-font-family);
    font-size: 22px; /* Use px instead of rem */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .inner {
        flex-direction: column;
    }

    .image {
        display: none;
    }

    .content {
        margin-right: 0;
    }

    .title {
        max-width: 100%;
        font-size: 34px; /* Use px instead of rem */
        line-height: 1.15;
    }

    .description {
        max-width: 100%;
    }

    .control {
        width: 100%;
    }
}
