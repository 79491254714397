.oswald-logo {
 font-family: "Oswald", sans-serif;
 font-size: 25px;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}

.oswald-username {
 font-family: "Oswald", sans-serif;
 font-size: 20px;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}