.wrapper {
    padding-top: calc(var(--mantine-spacing-xl) * 4);
    padding-bottom: calc(var(--mantine-spacing-xl) * 4);
}

.title {
    font-family: Greycliff CF, var(--mantine-font-family);
    font-weight: 900;
    margin-bottom: var(--mantine-spacing-md);
    text-align: center;
    font-size: 36px; /* Default font size for larger screens */
}

.description {
    max-width: 600px; /* Assuming rem() is not needed */
    margin: auto;
}

/* Media Queries */
@media (max-width: 768px) { /* Replace 768px with your breakpoint value */
    .title {
        font-size: 28px; /* Adjust font size for smaller screens */
        text-align: left;
    }

    .description {
        text-align: left;
    }
}